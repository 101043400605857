<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The inside diameter of an open cylindrical tank is
        <stemble-latex content="$(5.4\pm0.2)\,\text{m}$" />
        . The height of the tank is
        <stemble-latex content="$(9.8\pm0.5)\,\text{m}$" />
        . Calculate the volume in
        <stemble-latex content="$\text{m}^3$" />
        of the tank and the standard deviation of the result. (Volume of a cylinder =
        <stemble-latex content="$\pi\text{r}^2\text{h}$" />
        , where
        <stemble-latex content="$\text{r}$" />
        is the inside radius, and
        <stemble-latex content="$\text{h}$" />
        is the height)
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{Volume}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUPEI2210A1Q2',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
